import React from 'react';
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/solid';
import Image from "next/image";
import { Container } from './Container';
import { AnimateText } from './AnimateText';
import { AnimateImage } from './AnimateImage';

export function KeyPoints() {
  return (
    <section
      id="key-points"
      aria-label="CRE Broker features"
      className="pt-20 pb-14 lg:py-48"
    >
      <Container>
        <div className="flex flex-col-reverse lg:flex-row lg:items-center">
          <div className="lg:w-1/2 pt-5 lg:pr-10 o lg:pt-0">
            <h2 className="w-fit font-display text-xl p-2 rounded-md font-medium tracking-tight text-slate-900 bg-white drop-shadow-md">
              Not just another CRM.
            </h2>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              The most complete solutions to process deals.
            </h2>
            <AnimateText className="mt-8 space-y-4">
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-gray-600 ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-indigo-600" />
                Combining powerful customer relationship management tools with access to an exclusive lender network so you can increase your rate of closing deals.
              </p>
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-gray-600 ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-indigo-600" />
                Lenders are hand-selected to fill niche loan &quot;buckets&quot;. View lenders’ loan parameters to easily identify the best fit for your deals.
              </p>
            </AnimateText>
          </div>
          <AnimateImage className="lg:w-1/2 relative">
            <AnimateText className="absolute -top-14 left-2 p-2.5 bg-white rounded-md shadow-md w-auto">
              <p className="text-xs sm:text-sm font-bold text-center pb-2">Loan Parameters</p>
              <p className="text-xs sm:text-sm">
                <CheckIcon className="inline-block bg-indigo-600 w-4 h-4 mr-2 text-white" />
                Asset Class
              </p>
              <p className="text-xs sm:text-sm">
                <CheckIcon className="inline-block bg-indigo-600 w-4 h-4 mr-2 text-white" />
                Location
              </p>
              <p className="text-xs sm:text-sm">
                <CheckIcon className="inline-block bg-indigo-600 w-4 h-4 mr-2 text-white" />
                LTV
              </p>
              <p className="text-xs sm:text-sm">
                <CheckIcon className="inline-block bg-indigo-600 w-4 h-4 mr-2 text-white" />
                DSCR
              </p>
            </AnimateText>
            <Image
              className="rounded-md"
              src="https://fvsiteprodst01.blob.core.windows.net/images/check.png"
              alt="broker working"
              width={1000}
              height={1000}
            />
          </AnimateImage>
        </div>
      </Container>
    </section>
  );
}
