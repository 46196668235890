import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Image from "next/image";
import { Container } from './Container';
import { AnimateText } from './AnimateText';
import { AnimateImage } from './AnimateImage';

export function NeedsList() {
  return (
    <section
      id="key-points"
      aria-label="CRE Broker features"
      className="pt-20 pb-14 sm:pb-20 sm:pt-32 lg:py-48"
    >
      <Container>
        <div className="flex flex-col-reverse lg:flex-row lg:items-center">
          <div className="lg:w-1/2 pt-5 lg:pr-10 lg:pt-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Stop the document-chasing with your borrowers.
            </h2>
            <AnimateText className="mt-8 space-y-4">
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-gray-600 ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-indigo-600" />
                Reduce the time to receive borrower documents by 58%.
              </p>
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-gray-600 ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-indigo-600" />
                Automated reminders will take the document-gathering tasks out of your hands so you can spend more time turning leads into deals and deals into closings.
              </p>
            </AnimateText>
          </div>
          <AnimateImage className="lg:w-1/2 relative">
            <Image
              className="rounded-md"
              src="https://fvsiteprodst01.blob.core.windows.net/images/needs-list.png"
              alt="needs list"
              width={1000}
              height={1000}
            />
          </AnimateImage>
        </div>
      </Container>
    </section>
  );
}
