import React from 'react';
import { scroller } from 'react-scroll';
import { Container } from './Container';
import { AnimateText } from './AnimateText';
import { Button } from '../button/button';

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      aria-label="Request Demo"
      className="relative overflow-hidden bg-slate-50"
    >
      <Container className="relative">
        <AnimateText className="mx-auto max-w-lg text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Get started today
          </h2>
          <p className="text-xl text-gray-600 pt-5">
            Fundview is a broker-centric software and access is granted to CRE mortgage brokers only.
          </p>
          <p className="mt-4 pt-5">
            <Button label="Get started" onClick={() => scroller.scrollTo('demo_form', { offset: -150 })} />
          </p>
        </AnimateText>
      </Container>
    </section>
  );
}
