import React from 'react';
import Image from "next/image";
import {
  BellAlertIcon,
  CheckCircleIcon,
  ClockIcon,
  HeartIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Container } from './Container';
import { AnimateText } from './AnimateText';
import { AnimateImage } from './AnimateImage';

export function Borrowers() {
  return (
    <section
      id="features"
      aria-label="CRE Broker features"
      className="relative overflow-hidden bg-indigo-500 pt-20 pb-28 lg:py-48"
    >
      <Image
        className="absolute"
        src="https://fvsiteprodst01.blob.core.windows.net/images/background-call-to-action.jpg"
        alt="background features"
        fill
        unoptimized
      />
      <Container className="relative">
        <div className="flex flex-col-reverse lg:flex-row-reverse lg:items-center">
          <div className="lg:w-1/2 pt-5 lg:pl-10 lg:pt-0 lg:w-3/4">
            <h2 className="w-fit font-display text-xl p-2 rounded-md font-medium tracking-tight text-slate-900 bg-white shadow-md">
              Why use Fundview?
            </h2>
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl lg:text-5xl">
              Because your borrowers will thank you.
            </h2>
            <AnimateText className="mt-8 space-y-4">
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-white ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-white" />
                Borrowers can simply upload requested documents from their desktop or any mobile device. They can see real-time updates you make to their Needs List so you both will be on the same
                page.
              </p>
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-white ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-white" />
                Coordinate all the documents needed for initial application review, contracts, lender underwriting, funding conditions, or customize lists to your liking.
              </p>
            </AnimateText>
          </div>
          <AnimateImage className="lg:w-1/2 relative">
            <AnimateText className="absolute -top-14 left-2 lg:top-auto lg:-bottom-20 p-2.5 bg-white rounded-md shadow-md w-auto">
              <p className="text-xs sm:text-sm">
                <BellAlertIcon className="inline-block w-4 h-4 sm:w-6 sm:h-6 mr-2 text-indigo-500" />
                Automated Reminders
              </p>
              <p className="text-xs sm:text-sm">
                <HeartIcon className="inline-block w-4 h-4 sm:w-6 sm:h-6 mr-2 text-red-500" />
                Friendly Interface
              </p>
              <p className="text-xs sm:text-sm">
                <ClockIcon className="inline-block w-4 h-4 sm:w-6 sm:h-6 mr-2 text-black" />
                Real-Time updates
              </p>
              <p className="text-xs sm:text-sm">
                <XCircleIcon className="inline-block w-4 h-4 sm:w-6 sm:h-6 mr-2 text-green-500" />
                No paper-work needed
              </p>
            </AnimateText>
            <Image
              className="rounded-md"
              src="https://fvsiteprodst01.blob.core.windows.net/images/borrower.png"
              alt="borrower"
              width={1000}
              height={1000}
            />
          </AnimateImage>
        </div>
      </Container>
    </section>
  );
}
