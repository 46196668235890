import React from 'react';
import Image from "next/image";
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Container } from './Container';
import { AnimateText } from './AnimateText';
import { AnimateImage } from './AnimateImage';

export function Lenders() {
  return (
    <section
      id="features"
      aria-label="CRE Broker features"
      className="relative overflow-hidden bg-indigo-500 pt-20 pb-28 lg:py-48"
    >
      <Image
        className="absolute"
        src="https://fvsiteprodst01.blob.core.windows.net/images/background-call-to-action.jpg"
        alt="background features"
        fill
        unoptimized
      />
      <Container className="relative">
        <div className="flex flex-col-reverse lg:flex-row-reverse lg:items-center">
          <div className="lg:w-1/2 pt-5 lg:pl-10 lg:pt-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl lg:text-5xl">
              Quickly catch the lenders attention.
            </h2>
            <AnimateText className="mt-8 space-y-5">
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-white ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-white" />
                The Loan Request Generator will help create a one page deal summary.
              </p>
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-white ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-white" />
                Show the lender deal highlights as a prelude to you full loan package.
              </p>
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-white ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-white" />
                Fundview calculates and displays your deal metrics to allow the lender to make quick feasibility analysis.
              </p>
            </AnimateText>
          </div>
          <AnimateImage className="lg:w-1/2 relative">
            <Image
              className="rounded-md"
              src="https://fvsiteprodst01.blob.core.windows.net/images/lender.png"
              alt="needs list"
              width={1000}
              height={1000}
            />
          </AnimateImage>
        </div>
      </Container>
    </section>
  );
}
