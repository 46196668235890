import * as yup from 'yup';

export const contactFormValidationSchema = yup.object()
  .shape({
    firstName: yup.string()
      .required('This field is required')
      .min(3)
      .max(200),
    lastName: yup.string()
      .required('This field is required')
      .min(3)
      .max(200),
    email: yup.string()
      .required('This field is required')
      .email('This is not a valid email.')
      .max(200),
    phoneNumber: yup.string()
      .required('This field is required')
      .matches(/(^\d{10}$)/, 'This is not a valid phone number', { excludeEmptyString: true })
      .max(20),
    company: yup.string()
      .required('This field is required')
      .max(100),
    website: yup.string()
      .optional()
      .max(100)
      .nullable(true)
      .matches(/(^$|^(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$)/, 'This is not a valid website', { excludeEmptyString: true }),
    referrerName: yup.string()
      .when('referrerEmail', {
        is: (email) => !!email,
        then: yup.string()
          .required('This field is required.'),
      }),
    referrerEmail: yup.string()
      .when('referrerName', {
        is: (name) => !!name,
        then: yup.string()
          .required('This field is required.')
          .email('This is not a valid email.'),
      }),
  }, ['referrerName', 'referrerEmail']);
