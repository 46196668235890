import React from 'react';
import Image from "next/image";
import invitationIcon from '../../../public/images/icons/membership.png';
import collaborationIcon from '../../../public/images/icons/collaboration.png';
import networkIcon from '../../../public/images/icons/connection.png';
import { Container } from './Container';
import ContactForm from '../forms/contact_form/contact_form';

export function Hero() {
  return (
    <Container className="pt-36 pb-14 text-center lg:text-left lg:py-36">
      <div className="flex flex-col lg:space-x-14 lg:flex-row">
        <div className="lg:w-2/3">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-6xl">
            Take your commercial mortgage brokerage to a new
            <span className="relative whitespace-nowrap text-indigo-600">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70"
                preserveAspectRatio="none"
              >
                <path
                  d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"
                />
              </svg>
              <span className="relative">
                {' '}
                level.
              </span>
            </span>
          </h1>
          <p className="mt-6 text-xl tracking-tight text-gray-600 text-justify">
            Revolutionize your commercial mortgage brokerage business with our cutting-edge Dealflow CRM software.
          </p>
          <p className="mt-6 text-xl tracking-tight text-gray-600 text-justify">
            Unlike other software that seeks to eliminate brokers, our platform is designed to elevate your role in the deal. As the quarterback of the transaction, we provide you with the tools to execute your game plan and demonstrate your value as the MVP.
          </p>
          <p className="mt-6 text-xl tracking-tight text-gray-600 text-justify">
            Our CRM system is custom-built for the unique needs of the commercial mortgage industry, specifically from the perspective of brokers. No longer will you have to work around the limitations of a generic CRM system. Stop working harder and start working smarter. Start using Fundview and take your commercial mortgage brokerage business to the next level.
          </p>
          <div className="text-left py-10 space-y-5">
            <div className="relative">
              <Image
                className="absolute text-indigo-600"
                src={invitationIcon}
                alt="Invitation"
                height="50"
                width="50"
              />
              <p className="text-md max-w-2xl tracking-tight text-gray-600 ml-16 text-justify">
                Gain access to our full suite of online broker tools, including unlimited document tracking and organization, borrower notifications, and other CRM features that streamline your workflow and enhance your borrower and lender relationships.
              </p>
            </div>
            <div className="relative">
              <Image
                className="absolute text-indigo-600"
                src={networkIcon}
                alt="Network"
                height="50"
                width="50"
              />
              <p className="text-md max-w-2xl tracking-tight text-gray-600 ml-16 text-justify">
                Fundview, is dedicated to advancing the commercial real estate loan industry by leveraging technology and intelligent tools that have long been overdue. Additionally, we prioritize the importance of relationships by introducing Preferred Lenders to bridge the gaps in loan programs within your network of lending sources.
              </p>
            </div>
            <div className="relative">
              <Image
                className="absolute text-indigo-600"
                src={collaborationIcon}
                alt="Collaboration"
                height="50"
                width="50"
              />
              <p className="text-md max-w-2xl tracking-tight text-gray-600 ml-16 text-justify">
                As a broker-user in our exclusive network, you will have a significant voice in shaping the future of the platform. Many of our users’ feedback and suggestions have been swiftly implemented, ensuring the software is being tailored to meet the unique needs of our industry.
              </p>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
      </div>
    </Container>
  );
}
