import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export function Container({
  className = '',
  ...props
}) {
  return (
    <div
      className={classNames('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)}
      {...props}
    />
  );
}

Container.propTypes = {
  className: PropTypes.string,
};
