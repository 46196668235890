import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

export function AnimateImage({
  className,
  children,
}) {
  return (
    <motion.div
      className={className}
      initial={{
        opacity: 0.3,
        x: '-100%',
      }}
      whileInView={{
        opacity: 1,
        x: 0,
      }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
}

AnimateImage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
