import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Label({
  name,
  caption,
  children,
  className,
}) {
  return (
    <label
      htmlFor={name}
      className={classNames('flex items-center text-sm font-medium dark:text-slate-300', className)}
    >
      {caption}
      {children}
    </label>
  );
}

Label.propTypes = {
  name: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Label;
