import React, { Fragment, useCallback } from "react";
import { scroller } from "react-scroll";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import isEmpty from "lodash/isEmpty";
import { Logo } from "../logo/logo";
import { Button } from "../button/button";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

function MobileNavLink({ href, children, onClick }) {
  if (!href) {
    return (
      <span className="cursor-pointer hover:text-indigo-600" onClick={onClick}>
        {children}
      </span>
    );
  }

  return (
    <Popover.Button as={Fragment}>
      <Link href={href} className="hover:text-indigo-600">
        {children}
      </Link>
    </Popover.Button>
  );
}

MobileNavLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={classNames("origin-center transition", open && "scale-90 opacity-0")}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={classNames("origin-center transition", !open && "scale-90 opacity-0")}
      />
    </svg>
  );
}

MobileNavIcon.propTypes = {
  open: PropTypes.bool,
};

function MobileNavigation({ userConnected = {} }) {
  const router = useRouter();

  const scrollToFaq = () => {
    scroller.scrollTo("faq", { offset: -100 });
    sendAnalytics(ANALYTIC_EVENTS.FAQ_CLICKED, { userConnected });
  };

  const scrollToPricing = () => {
    scroller.scrollTo("pricing", { offset: -100 });
    sendAnalytics(ANALYTIC_EVENTS.PRICING_CLICKED, { userConnected });
  };

  const handleSignIn = useCallback(() => router.push("/login?returnTo=dashboard"), [router]);

  const isUserConnected = !isEmpty(userConnected);

  return (
    <div>
      <Button className="hidden md:block" label="Sign in" onClick={handleSignIn} />
      <Popover>
        <Popover.Button
          className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
          aria-label="Toggle Navigation"
        >
          {({ open }) => <MobileNavIcon open={open} />}
        </Popover.Button>
        <Transition.Root>
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-150 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              as="div"
              className="absolute inset-x-0 top-full flex flex-col text-right md:rounded-md bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
            >
              <MobileNavLink onClick={scrollToPricing}>Pricing</MobileNavLink>
              <MobileNavLink onClick={scrollToFaq}>FAQ</MobileNavLink>
              {!isUserConnected && <MobileNavLink href="/login?returnTo=dashboard">Sign in</MobileNavLink>}
              {isUserConnected && (
                <>
                  <MobileNavLink href="/dashboard">Dashboard</MobileNavLink>
                  <MobileNavLink href="/logout">Sign out</MobileNavLink>
                </>
              )}
            </Popover.Panel>
          </Transition.Child>
        </Transition.Root>
      </Popover>
    </div>
  );
}

MobileNavigation.propTypes = {
  userConnected: PropTypes.object,
};

export function Header({ userConnected = {} }) {
  const router = useRouter();

  const handleSignIn = useCallback(() => router.push("/login?returnTo=dashboard"), [router]);

  const scrollToFaq = () => {
    scroller.scrollTo("faq", { offset: -100 });
    sendAnalytics(ANALYTIC_EVENTS.FAQ_CLICKED, { userConnected });
  };

  const scrollToPricing = () => {
    scroller.scrollTo("pricing", { offset: -100 });
    sendAnalytics(ANALYTIC_EVENTS.PRICING_CLICKED, { userConnected });
  };

  const isUserConnected = !isEmpty(userConnected);

  return (
    <header className="fixed w-full top-0 z-50 lg:mt-5">
      <div className="flex justify-center">
        <nav className="relative flex justify-between w-full lg:w-3/4 p-3 shadow-md lg:rounded-md bg-white">
          <div className="flex items-center">
            <Logo className="flex" clickable={false} colored />
          </div>
          <div className="flex items-center gap-x-5">
            <div className="hidden md:flex space-x-5">
              <button
                type="button"
                onClick={scrollToPricing}
                className="flex text-lg font-normal hover:text-indigo-600 relative z-10 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
              >
                Pricing
              </button>
              <button
                type="button"
                onClick={scrollToFaq}
                className="flex text-lg font-normal hover:text-indigo-600 relative z-10 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
              >
                FAQ
              </button>
            </div>
            <div className="relative hidden md:block">
              {!isUserConnected && <Button label="Sign in" onClick={handleSignIn} />}
              {isUserConnected && (
                <Popover>
                  <Popover.Button
                    className="text-lg font-normal hover:text-indigo-600 relative z-10 flex items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
                    aria-label="Toggle Navigation"
                  >
                    <span className="text-inherit">
                      Welcome, {userConnected?.user_metadata?.firstName || userConnected.given_name}
                    </span>
                    <ChevronDownIcon className="w-5 h-5 ml-1 " />
                  </Popover.Button>
                  <Transition.Root>
                    <Transition.Child
                      as={Fragment}
                      enter="duration-150 ease-out"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-150 ease-in"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
                    </Transition.Child>
                    <Transition.Child
                      as={Fragment}
                      enter="duration-150 ease-out"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="duration-100 ease-in"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Popover.Panel
                        as="div"
                        className="absolute inset-x-0 mt-2 flex flex-col text-right rounded-md bg-white py-2 pr-5 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
                      >
                        {!isUserConnected && <MobileNavLink href="/login?returnTo=dashboard">Sign in</MobileNavLink>}
                        {isUserConnected && (
                          <>
                            <MobileNavLink href="/dashboard">Dashboard</MobileNavLink>
                            <MobileNavLink href="/logout">Sign out</MobileNavLink>
                          </>
                        )}
                      </Popover.Panel>
                    </Transition.Child>
                  </Transition.Root>
                </Popover>
              )}
            </div>
            <div className="-mr-1 md:hidden">
              <MobileNavigation userConnected={userConnected} />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  userConnected: PropTypes.object,
};
