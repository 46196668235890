import React, { Fragment } from 'react';
import {
  MinusIcon,
  CogIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Container } from './Container';
import FeatureInProgress from '../information_section/information_section';

const tiers = [
  {
    name: 'Broker',
  },
  {
    name: 'Borrower',
  },
  {
    name: 'Lender',
  },
  {
    name: 'Referrer',
  },
];
const sections = [
  {
    name: 'Features',
    features: [
      {
        name: 'Loan Dashboard',
        tiers: {
          Broker: true,
          Borrower: true,
        },
      },
      {
        name: 'Update Lender Parameters',
        tiers: {
          Broker: true,
          Lender: 'U',
        },
      },
      {
        name: 'Sort Loans that fit lender Criteria',
        tiers: {
          Broker: true,
        },
      },
      {
        name: 'Import Contacts - Connect to Google and Microsoft Accounts',
        tiers: {
          Broker: true,
        },
      },
      {
        name: 'Emails notifications of outstanding docs ',
        tiers: {
          Broker: true,
          Borrower: true,
        },
      },
      {
        name: 'Email notifications for change of loan status',
        tiers: {
          Broker: true,
          Borrower: true,
          Lender: 'U',
          Referrer: true,
        },
      },
      {
        name: 'Manually send updated Needs List ',
        tiers: {
          Broker: true,
        },
      },
      {
        name: 'Email notification of newly uploaded documents',
        tiers: {
          Broker: true,
        },
      },
      {
        name: 'Create and modify custom Needs List',
        tiers: {
          Broker: true,
        },
      },
      {
        name: 'Upload and fulfill Needs List items from any device',
        tiers: {
          Broker: true,
        },
      },
      {
        name: 'Bulk email campaigns',
        tiers: {
          Broker: 'U',
        },
      },
      {
        name: 'Loan Request Summary Generator',
        tiers: {
          Broker: true,
        },
      },
      {
        name: 'Document Package Generator',
        tiers: {
          Broker: 'U',
        },
      },
      {
        name: 'Access verified Lender Roster to maximize funding sources',
        tiers: {
          Broker: true,
        },
      },
    ],
  },
];

export function FeaturesTable() {
  return (
    <section
      id="features-table"
      aria-label="CRE Broker features"
      className="relative pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32 bg-gradient-to-r from-indigo-400 to-indigo-500"
    >
      <Container>
        <div className="mx-auto text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl lg:text-5xl">
            Features
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Below you can find an extensive list of Fundview features with many more in the pipeline.
          </p>
        </div>
        <div className="mx-auto max-w-7xl py-16 sm:py-24 sm:px-6 lg:px-8">
          {/* xs to lg */}
          <div className="mx-auto max-w-2xl space-y-16 lg:hidden">
            {tiers.map((tier) => (
              <section key={tier.name}>
                <div className="mb-8 px-4">
                  <h2 className="text-lg font-medium leading-6 text-white">{tier.name}</h2>
                </div>

                {sections.map((section) => (
                  <table key={section.name} className="w-full">
                    <caption className="border-t border-slate-300 py-3 px-4 text-left text-sm font-medium text-white">
                      {section.name}
                    </caption>
                    <thead>
                      <tr>
                        <th className="sr-only" scope="col">
                          Feature
                        </th>
                        <th className="sr-only" scope="col">
                          Included
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-300">
                      {section.features.map((feature) => (
                        <tr key={feature.name} className="border-t border-slate-300">
                          <th className="py-5 pl-6 text-left text-sm font-normal text-white" scope="row">
                            {feature.name}
                          </th>
                          <td className="py-5 pr-4">
                            {typeof feature.tiers[tier.name] === 'string' && (
                            <FeatureInProgress
                              className="-translate-x-full"
                              button={(
                                <span className="inline-flex px-1 rounded text-xs font-medium">
                                  <CogIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </span>
                              )}
                              title="Feature in Progress"
                              description="We are currently working on this functionality. Request your invitation to find out more about the Fundview Suite!"
                            />
                            )}
                            {feature.tiers[tier.name] === true && (
                            <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            )}
                            {!feature.tiers[tier.name] && (
                            <MinusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
              </section>
            ))}
          </div>

          {/* lg+ */}
          <div className="hidden lg:block">
            <table className="h-px w-full table-fixed">
              <caption className="sr-only">Features Table</caption>
              <thead>
                <tr>
                  <th className="w-1/4 px-6 pb-4 text-left text-sm font-medium text-white" scope="col">
                    <span className="sr-only">Feature by</span>
                  </th>
                  {tiers.map((tier) => (
                    <th
                      key={tier.name}
                      className="w-1/4 px-6 pb-4 text-left text-lg font-medium leading-6 text-white text-center"
                      scope="col"
                    >
                      {tier.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-200 border-t border-slate-200">
                {sections.map((section) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        className="py-3 pl-6 text-left text-sm font-medium text-white"
                        colSpan={5}
                        scope="colgroup"
                      >
                        {section.name}
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th className="py-3 pl-8 text-left text-sm font-normal text-white" scope="row">
                          {feature.name}
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.name} className="py-3 px-6">
                            <div className="flex justify-center">
                              {typeof feature.tiers[tier.name] === 'string' && (
                              <FeatureInProgress
                                button={(
                                  <span className="inline-flex px-1 rounded text-xs font-medium">
                                    <CogIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                  </span>
                                )}
                                title="Feature in Progress"
                                description="We are currently working on this feature. Request your invitation to know more about Fundview Suite!"
                              />
                              )}
                              {feature.tiers[tier.name] === true && (
                              <CheckCircleIcon className="inline-block w-6 h-6 mr-1 text-white" />
                              )}
                              {!feature.tiers[tier.name] && (
                              <MinusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </section>
  );
}
