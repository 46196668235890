import React, { useState } from 'react';
import Image from "next/image";
import PropTypes from 'prop-types';
import { Container } from './Container';
import { Button } from '../button/button';

function ReadMore({ children }) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="mt-4 text-sm text-gray-600 inline text-justify">
      {isReadMore && text.length > 300 ? (
        <span>
          {text.slice(0, 300)}
          ...&nbsp;
        </span>
      ) : `${text} `}
      {text.length > 300 && (
        <Button label={isReadMore ? 'read more' : 'read less'} isLink onClick={toggleReadMore} />
      )}
    </p>
  );
}

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-40"
    >
      <Image
        className="absolute"
        src="https://fvsiteprodst01.blob.core.windows.net/images/background-faqs.jpg"
        alt="background faqs"
        fill
        unoptimized
      />
      <Container className="relative">
        <div className="text-center mx-auto lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Frequently asked questions
          </h2>
          <p className="text-xl text-gray-600 pt-5">
            If you can’t find what you’re looking for, email our support team
            at
            {' '}
            <a className="text-indigo-500" href="mailto:support@fundview.com">
              support@fundview.com
            </a>
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          <div>
            <h3 className="font-display text-lg leading-7 text-gray-900">
              Why is access only for commercial mortgage brokers?
            </h3>
            <p className="mt-4 text-sm text-gray-600 inline">
              Fundview is a
              {' '}
              <span className="font-bold">broker-centric</span>
              {' '}
            </p>
            <ReadMore>
              platform that understands the important role that brokers play in the transaction as an intermediary. Most CRE financing software attempt to make workflow
              more efficient by eliminating intermediaries, but we believe the CRE loan industry is unique in the sense that the broker will always play a crucial role in the successful arranging and
              placement of financing request. This platform was created to make deal flow much more efficient by introducing tools for the processing needs of the broker. These tools were specifically
              designed from the brokers perspective and unique to our industry. No need for workarounds of generic CRM systems.
            </ReadMore>
          </div>
          <div>
            <h3 className="font-display text-lg leading-7 text-gray-900">
              How are Preferred Lenders selected?
            </h3>
            <ReadMore>
              Although many marketplace sites will promote the access to “thousands” of CRE lenders, we believe our exclusive network extends to our Preferred Lenders. Brokers don’t need thousands of
              options to close a deal, but you do need ones that have a proven track record of funding and not just rubber-stamping term sheets. Some of our founding partners have been successful
              mortgage brokers that closed hundreds of millions in financing transactions, with decades of experience and have forged excellent business contacts with lenders over that span. Fundview
              is therefore connecting these lending sources to the broker-users to foster new business relationship where both parties can benefit from connecting with the common goal of closing
              financing requests.
              Our team will be continuously adding new lenders to the preferred network. Our goal is not to have brokers sort through a large quantity of various lenders, but rather identify the best
              lender for specific asset classes, transaction types, geography, niche and clearly identifying their current lending parameters to streamline loan processing.
            </ReadMore>
          </div>
          <div>
            <h3 className="font-display text-lg leading-7 text-gray-900">
              What differentiates one Preferred Lender from another?
            </h3>
            <ReadMore>
              In addition to having loan programs for all the main CRE “food groups”, our lending network is curated by highlighting specific niches or specialties that differentiate one from another.
              Most of our lenders are commercial banks and balance sheet lenders that have created special programs that make them unique from each other. So having access to these lenders with unique
              and “hard-to-place” loan programs gives the broker a competitive advantage.
            </ReadMore>
          </div>
          <div />
          <div>
            <h3 className="font-display text-lg leading-7 text-gray-900">
              Is there anything to download or subscribe to?
            </h3>
            <ReadMore>
              No, the full suite of features are available once you log in. There are no commitments, credit cards, premium tiers, or add-ons.
            </ReadMore>
          </div>
          <div />
        </div>
      </Container>
    </section>
  );
}
