import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Link from 'next/link';
import Script from 'next/script';
import Confetti from 'react-confetti';
import classNames from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Button } from '../../button/button';
import Label from '../../label/label';
import { reactPlugin } from '../../application_insights/application_insights';
import { contactFormValidationSchema } from './validator';
import { createDemoAccess } from '../../../redux/actions/contact_form';
import { RECAPTCHA_ACTION_NAMES } from '../../../utils/applicationConstants';
import useWindowSize from '../../../hooks/useWindowSize';
import { ANALYTIC_EVENTS, sendAnalytics } from '../../../utils/analytics';

function ContactForm() {
  const dispatch = useDispatch();
  const [startConfetti, setStartConfetti] = useState(false);
  const {
    width,
    height,
  } = useWindowSize();
  const { userConnected } = useSelector((state) => state.currentUser);
  const { isProcessing } = useSelector((state) => state.contactForm);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(contactFormValidationSchema),
  });

  useEffect(() => {
    if (startConfetti) {
      setTimeout(() => {
        setStartConfetti(false);
      }, 10000);
    }
  }, [startConfetti]);

  const handleResetForm = useCallback(() => {
    setStartConfetti(true);
    reset();
  }, [reset]);

  const onSubmit = useCallback((data) => {
    window.grecaptcha.enterprise.execute(process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY, { action: RECAPTCHA_ACTION_NAMES.DEMO_FORM })
      .then((token) => {
        dispatch(createDemoAccess({
          ...data,
          page: RECAPTCHA_ACTION_NAMES.DEMO_FORM,
          token,
        }, () => {
          sendAnalytics(ANALYTIC_EVENTS.DEMO_REQUESTED, { userConnected, ...data });
          handleResetForm();
        }));
      });
  }, [dispatch, userConnected, handleResetForm]);

  return (
    <div className="bg-indigo-600 rounded-md">
      {startConfetti && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={500}
          recycle={false}
        />
      )}
      <Script
        id="google-recaptcha"
        strategy="lazyOnload"
        src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY}`}
      />
      <form id="demo_form" onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="flex-auto grow py-6 px-4 sm:px-6">
          <h1 className="text-left text-3xl text-white font-bold">
            Try FREE for 30 days
          </h1>
          <h5 className="text-left text-md text-white mt-1 mb-8">
            No credit card required.
          </h5>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-3">
              <div className="grid grid-cols-6 gap-6">
                {/* First Name */}
                <div className="col-span-3">
                  <Label name="firstName" caption="*First Name" className="text-white dark:text-white" />
                  <input
                    {...register('firstName')}
                    type="text"
                    placeholder="First Name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.firstName?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.firstName?.message}
                  </p>
                </div>
                {/* Last Name */}
                <div className="col-span-3">
                  <Label name="lastName" caption="*Last Name" className="text-white dark:text-white" />
                  <input
                    {...register('lastName')}
                    type="text"
                    placeholder="Last Name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.lastName?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.lastName?.message}
                  </p>
                </div>
                {/* Email */}
                <div className="col-span-6">
                  <Label name="email" caption="*Email" className="text-white dark:text-white" />
                  <input
                    {...register('email')}
                    type="text"
                    placeholder="Email"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.email?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.email?.message}
                  </p>
                </div>
                {/* Phone Number */}
                <div className="col-span-6">
                  <Label name="phoneNumber" caption="*Phone Number" className="text-white dark:text-white" />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <NumericFormat
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onValueChange={(values) => field.onChange(values.value)}
                        getInputRef={field.ref}
                        value={field.value}
                        displayType="input"
                        type="text"
                        prefix="+1 "
                        placeholder="(999) 999 9999"
                        format="+1 (###) ### ####"
                        mask="_"
                      />
                    )}
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.phoneNumber?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.phoneNumber?.message}
                  </p>
                </div>
                {/* Company */}
                <div className="col-span-6">
                  <Label name="company" caption="*Company" className="text-white dark:text-white" />
                  <input
                    {...register('company')}
                    type="text"
                    placeholder="Company"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.company?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.company?.message}
                  </p>
                </div>
                {/* Company Website */}
                <div className="col-span-6">
                  <Label name="website" caption="Company Site" className="text-white dark:text-white" />
                  <input
                    {...register('website')}
                    type="text"
                    placeholder="www.site.com or site.com"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.website?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.website?.message}
                  </p>
                </div>
                {/* Referrer By */}
                <div className="col-span-3">
                  <Label name="referrerName" caption="Referred By" className="text-white dark:text-white" />
                  <input
                    {...register('referrerName')}
                    type="text"
                    placeholder="Referrer Name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.referrerName?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.referrerName?.message}
                  </p>
                </div>
                {/* Referrer Email */}
                <div className="col-span-3">
                  <input
                    {...register('referrerEmail')}
                    type="text"
                    placeholder="Referrer Email"
                    className="mt-6 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className={classNames('flex space-x-1 mt-2 text-left text-sm text-yellow-300', { hidden: !errors.referrerEmail?.message })}>
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-300 mr-1" aria-hidden="true" />
                    {errors.referrerEmail?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="mt-5 text-left text-xs text-slate-100">
            This site is protected by reCAPTCHA and the Google
            {' '}
            <Link
              href="https://policies.google.com/privacy"
              className="font-bold hover:underline">
              Privacy Policy
            </Link>
            {' '}
            and
            {' '}
            <Link
              href="https://policies.google.com/terms"
              className="font-bold hover:underline">
              Terms of Service
            </Link>
            {' '}
            apply.
          </p>
          <p className="mt-2 text-left text-xs text-slate-100">
            By registering, you agree with the processing of your personal data by Fundview as described in our
            {' '}
            <Link href="/policy" className="font-bold hover:underline">
              Privacy Notice
            </Link>
            .
          </p>
        </div>
        <div className="flex-shrink-0 pb-5 pr-5 flex justify-end bottom-0">
          <Button
            label="Sign up"
            type="submit"
            color="default"
            className="text-gray-900 dark:text-gray-900"
            isLoading={isProcessing}
          />
        </div>
      </form>
    </div>
  );
}

export default withAITracking(reactPlugin, ContactForm);
